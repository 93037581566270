export const userTypeMap = {
    SystemUser:"系統使用者",
    WebUser:"網頁使用者"
}

export const identityTypeMap = {
    TokenQrCode:"票券 QR Code",
    UserQrCode:"使用者 QR Code",
    DoorQrCode:"大門 QR Code",
    UserFace:"人臉",
    Fingerprint:"指紋",
    NfcCard:"NFC卡"
} 

export const actionMap = {
    DoorIn:"進",
    DoorOut :"出"
}