import ApiService from "@/core/services/ApiService";

// 查詢space的accessDevice的門禁紀錄
export function getAccessDeviceLog(spaceGuid: string,request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/AccessLog`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}