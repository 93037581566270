import ApiService from "@/core/services/ApiService";

// 查詢space的accessDevice的門禁紀錄
export function getAccessDevice(spaceGuid: string) {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/AccessDevice`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}