<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <div class="card-title row col">
        <div class="row col">
          <div
            class="
              col-lg-3 col-12
              d-flex
              align-items-center
              position-relative
              my-1
            "
          >
            <span class="svg-icon svg-icon-1 position-absolute ms-6">
              <inline-svg src="media/icons/duotune/general/gen021.svg" />
            </span>
            <input
              type="text"
              v-model="search"
              @input="tableSearch()"
              class="form-control form-control-solid ps-15"
              placeholder="搜尋"
            />
          </div>
          <div class="col-lg col-12 d-flex align-items-center overflow-auto">
            <el-date-picker
              v-model="value"
              @change="changeDate"
              class="ms-xxl-5 ms-xl-5 ms-0"
              type="datetimerange"
              start-placeholder="開始日期"
              end-placeholder="結束日期"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-fileImg="{ row: tableData }">
          <img
            data-bs-toggle="modal"
            data-bs-target="#fileImg-modal"
            @click="setCurrentImg(tableData.fileImg)"
            class="fileImg rounded"
            :src="getImage(tableData.fileImg)"
            alt="image"
          />
        </template>
         <template v-slot:cell-guid="{ row: tableData }">
          {{ tableData.guid }}
        </template> 
        <template v-slot:cell-device="{ row: tableData }">
          {{ tableData.device }}
        </template>
        <template v-slot:cell-identityType="{ row: tableData }">
          {{ tableData.identityType }}
        </template>
        <template v-slot:cell-userType="{ row: tableData }">
          {{ tableData.userType }}
        </template>
        <template v-slot:cell-user="{ row: tableData }">
          {{ tableData.user }}
        </template>
        <template v-slot:cell-action="{ row: tableData }">
          {{ tableData.action }}
        </template>
        <template v-slot:cell-temperature="{ row: tableData }">
          {{ tableData.temperature }}°C
        </template>
        <template v-slot:cell-createTime="{ row: tableData }">
          {{ tableData.createTime }}
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 照片modal -->
  <div
    class="modal fade"
    id="fileImg-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="updateGroups-exampleModalLabel">照片</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
            <img
              data-bs-toggle="modal"
              data-bs-target="#fileImg-modal"
              :src="getImage(currentImg)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, toRefs } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import localstorage from "@/core/services/LocalstorageService";
import { getAccessDeviceLog } from "@/core/services/api/accessLog";
import moment from "moment";
import { getUtcTimeString, getLocalTimeString } from "@/core/utils/timeString";
import { getAccessDevice } from "@/core/services/api/accessDevice";
import { getFilePath } from "@/core/utils/file";
import {
  userTypeMap,
  identityTypeMap,
  actionMap,
} from "@/core/services/responsesModel/accessLog";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const datePicker = reactive({
      value: [
        moment().startOf("week").utc().format("YYYY-MM-DDTHH:mm:ssZ"),
        moment().subtract().endOf("day").utc().format("YYYY-MM-DDTHH:mm:ssZ"),
      ],
    });
    const currentImg = ref("");
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
        { key: "guid", name: "guid" },
      { key: "fileImg", name: "照片" },
      { key: "device", name: "裝置", sortable: true },
      { key: "identityType", name: "辨識方式", sortable: true },
      { key: "userType", name: "使用者類別", sortable: true },
      { key: "user", name: "使用者", sortable: true },
      { key: "action", name: "進/出", sortable: true },
      { key: "temperature", name: "溫度", sortable: true },
      { key: "createTime", name: "時間", sortable: true },
      //   { key: "operate", name: "功能" },
    ]);
    const isLoadind = ref(false);

    const setCurrentImg = (imgFiles) => {
      currentImg.value = imgFiles;
    };

    const changeDate = async () => {
      await setTableData();
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };
  

    const deviceList: any = reactive([]);
    const setAccessDeviceList = async () => {
      let device: any = await getAccessDevice(currentSpaceGuid!);
      deviceList.splice(0, deviceList.length, ...device);
      console.log("deviceList", deviceList);
    };

    const getDeviceByGuid = (guid) => {
      return deviceList.filter((o) => o.guid == guid)[0];
    };

    const handleGetLog = async () => {
      let request = {
        beginTime: getUtcTimeString(datePicker.value[0]),
        endTime: getUtcTimeString(datePicker.value[1]),
      };
      let log: any = await getAccessDeviceLog(currentSpaceGuid!, request);
      let logs = log.sort(function (a, b) {
        let atime = moment(a.createTime);
        let btime = moment(b.createTime);
        let result = atime.isAfter(btime);
        return result ? -1 : 0;
      });
      let times = logs.map((o) =>
        moment(o.createTime).format("YYYY-MM-DD HH:mm:ss")
      );

      return logs;
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let logs: any = await handleGetLog();
      console.log("logs", logs);
      tableData.splice(0);
      for (const log of logs) {
        tableData.push({
          guid: log.guid,
          fileImg: log.file,
          device: getDeviceByGuid(log.accessDevice).name,
          identityType: identityTypeMap[log.identityType],
          userType: userTypeMap[log.userType],
          user: log.simpleUser.name,
          action: actionMap[log.action],
          temperature: `${log.temperature}`,
          createTime: getLocalTimeString(log.createTime),
        });
      }
      initTableData.splice(0, initTableData.legth, ...tableData);
      isLoadind.value = false;
    };

    const getImage = (guid) => {
      if (guid !== "" && guid !== null) {
        return getFilePath(guid);
      } else {
        return "";
      }
    };

    const init = async () => {
      await setAccessDeviceList();
      await setTableData();
    };
    init();

    return {
      ...toRefs(datePicker),
      tableData,
      tableSearch,
      tableHeader,
      search,
      isLoadind,
      changeDate,
      getImage,
      setCurrentImg,
      currentImg,
    };
  },
});
</script>

<style scoped>
.fileImg {
  width: 75px;
  height: 75px;
  cursor: pointer;
}
</style>