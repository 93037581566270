import moment from "moment";

/**
 * 根據搜尋過濾列表資料
 * 
 * @param tableData 列表資料
 * @param initTableData 初始列表資料
 * @param searchValue 輸入搜尋值
 */
export function filterTableDataBySearch(tableData: Array<any>, initTableData: Array<any>, searchValue: string) {
    tableData.splice(
        0,
        tableData.length,
        ...searchItems(searchValue, initTableData)
    );
}

export interface BeginEndModel {
    beginTime: string,
    endTime: string
}
/**
 * 根據beginTime,endTime過濾列表資料
 * 
 * @param tableData 包含beginTime,endTime的列表
 * @param beginEndTime 過濾的起訖時間
 */
export function filterTableDataByBeginEnd(tableData: Array<BeginEndModel>, beginEndTime: BeginEndModel) {
    const filteredTableData: Array<BeginEndModel> = []
    tableData.forEach(tableItem => {
        if (isDataInTimeRange(tableItem, beginEndTime)) {
            filteredTableData.push(tableItem)
        }
    })
    return filteredTableData
}

/**
 * 是否在起迄時間內
 * 
 * @param data 需要判斷的物件
 * @param beginEndTime 起迄時間
 */
function isDataInTimeRange(data: BeginEndModel, beginEndTime: BeginEndModel) {
    return moment(data.beginTime).isAfter(beginEndTime.beginTime) && moment(beginEndTime.endTime).isAfter(data.endTime)
}

// 搜尋列表
export function searchItems(search, tableData: any) {
    if (search !== "") {
        const results: any = [];
        for (let j = 0; j < tableData.length; j++) {
            if (searchingFunc(tableData[j], search)) {
                results.push(tableData[j]);
            }
        }
        return results
    }
    return tableData
}

function searchingFunc(obj, value): boolean {
    for (const key in obj) {
        if (typeof obj[key] === "string") {
            // if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
            if (obj[key].indexOf(value) != -1) {
                return true;
            }
        } else if (Array.isArray(obj[key])) {
            for (const item of obj[key]) {
                if (typeof item === "string") {
                    if (item.indexOf(value) != -1) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};

