import moment from "moment";

export function getUtcTimeString(str, format = "YYYY-MM-DDTHH:mm:ss") {
  return moment(str).utc().format(format) + "Z";
}

export function getLocalTimeString(str, format = "YYYY-MM-DD HH:mm:ss") {
  return moment(str).format(format);
}
export function getMomentTime(str) {
  return moment(str);
}

export function getDefaultTimeString() {
  return "0001-01-01T00:00:00Z";
}
export function getDefaultEndTimeString() {
  return "9999-12-31T23:59:59Z";
}
export function getShiftBeginTimeString(str, format = "YYYY-MM-DD HH:mm:ss") {
  if (!str || str==0) {
    return getDefaultTimeString();
  }
  console.log('str1',str)
  return moment(str).format();
}
export function getShiftEndTimeString(str, format = "YYYY-MM-DD HH:mm:ss") {
  if (!str || str==0) {
   return getDefaultTimeString();
  }
    console.log('str2',str)
  //return "0001-01-03T" + moment(str).format("HH:mm:ss") + "Z";
  return moment(str).add(1,'days').format();
}

// export function getTimeDiff(begin, end, measurement = 'minutes') {
//   const beginTime = moment(begin)
//   const endTime = moment(end)
//   return endTime.diff(beginTime, measurement)
// }
